@import url(./assets/fonts/Morabba/fontiran.css);
@import url(./assets/fonts/YekanBakhFaNum/fontiran.css);

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]{
    direction: ltr;
  }
  input[type="number"]::placeholder{
    text-align: right;
  }
  input{
    outline: none;
  }
  body {
    margin: 0;
  }
  *{
    font-family: Yekan Bakh FaNum;
  }

  .dv-ltr *{
    font-family: "Helvetica";
  }
  .dv-ltr input{
    direction: ltr!important;
    text-align: left!important;
  }
  .dv-ltr input::placeholder{
    text-align: left;
  }

  .character {
    font-size: 20px;

    /* light theme */
    color: #272729;
    box-shadow: 0 2px 0 #e4e2f5;
    width: 44px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
  }
}

.vi__wrapper{
  margin: auto !important;
}
:where(.vi__wrapper), :where(.vi__container){
  width: 100% !important;
}

.rtl{
  direction: rtl;
}

.ltr{
  direction: ltr;
}

.text-sm {font-size: 12px;line-height: 22px;}
.text-md {font-size: 14px;line-height: 24px;}
.text-base {font-size: 16px;line-height: 26px;}
.text-lg {font-size: 18px;line-height: 28px;}
.text-xl {font-size: 22px;line-height: 32px;}
.text-xxl {font-size: 25px;line-height: 35px;}
.text-3xl {font-size: 30px;line-height: 40px;}

@media screen and (min-width: 320px) {
  .text-sm {font-size: calc(11px + 12 * ((100vw - 320px) / 1200));line-height: calc(22px + 12 * ((100vw - 320px) / 1200));}
  .text-md {font-size: calc(13px + 12 * ((100vw - 320px) / 1200));line-height: calc(23px + 12 * ((100vw - 320px) / 1200));}
  .text-base {font-size: calc(16px + 12 * ((100vw - 320px) / 1200));line-height: calc(26px + 12 * ((100vw - 320px) / 1200));}
  .text-lg {font-size: calc(18px + 12 * ((100vw - 320px) / 1200));line-height: calc(28px + 12 * ((100vw - 320px) / 1200));}
  .text-xl {font-size: calc(22px + 12 * ((100vw - 320px) / 1200));line-height: calc(32px + 12 * ((100vw - 320px) / 1200));}
  .text-xxl {font-size: calc(25px + 12 * ((100vw - 320px) / 1200));line-height: calc(35px + 12 * ((100vw - 320px) / 1200));}
  .text-3xl {font-size: calc(30px + 12 * ((100vw - 320px) / 1200));line-height: calc(40px + 12 * ((100vw - 320px) / 1200));}
}

@media screen and (min-width: 768px) {
  .text-sm {font-size: calc(12px + 12 * ((100vw - 768px) / 800));line-height: calc(22px + 12 * ((100vw - 768px) / 800));}
  .text-md {font-size: calc(14px + 12 * ((100vw - 768px) / 800));line-height: calc(24px + 12 * ((100vw - 768px) / 800));}
  .text-base {font-size: calc(16px + 12 * ((100vw - 768px) / 800));line-height: calc(26px + 12 * ((100vw - 768px) / 800));}
  .text-lg {font-size: calc(18px + 12 * ((100vw - 768px) / 800));line-height: calc(28px + 12 * ((100vw - 768px) / 800));}
  .text-xl {font-size: calc(22px + 12 * ((100vw - 768px) / 800));line-height: calc(32px + 12 * ((100vw - 768px) / 800));}
  .text-xxl {font-size: calc(25px + 12 * ((100vw - 768px) / 800));line-height: calc(35px + 12 * ((100vw - 768px) / 800));}
  .text-3xl {font-size: calc(30px + 12 * ((100vw - 768px) / 800));line-height: calc(40px + 12 * ((100vw - 768px) / 800));}
}

@media screen and (min-width: 1024px) {
  .text-sm {font-size: calc(12px + 12 * ((100vw - 1024px) / 980));line-height: calc(22px + 12 * ((100vw - 1024px) / 980));}
  .text-md {font-size: calc(14px + 12 * ((100vw - 1024px) / 980));line-height: calc(24px + 12 * ((100vw - 1024px) / 980));}
  .text-base {font-size: calc(16px + 12 * ((100vw - 1024px) / 980));line-height: calc(26px + 12 * ((100vw - 1024px) / 980));}
  .text-lg {font-size: calc(18px + 12 * ((100vw - 1024px) / 980));line-height: calc(28px + 12 * ((100vw - 1024px) / 980));}
  .text-xl {font-size: calc(22px + 12 * ((100vw - 1024px) / 980));line-height: calc(32px + 12 * ((100vw - 1024px) / 980));}
  .text-xxl {font-size: calc(25px + 12 * ((100vw - 1024px) / 980));line-height: calc(35px + 12 * ((100vw - 1024px) / 980));}
  .text-3xl {font-size: calc(30px + 12 * ((100vw - 1024px) / 980));line-height: calc(40px + 12 * ((100vw - 1024px) / 980));}
}

@media screen and (min-width: 1400px) {
  .text-sm {font-size: calc(12px + 10 * ((100vw - 1400px) / 1700));line-height: calc(22px + 10 * ((100vw - 1400px) / 1700));}
  .text-md {font-size: calc(14px + 10 * ((100vw - 1400px) / 1700));line-height: calc(24px + 10 * ((100vw - 1400px) / 1700));}
  .text-base {font-size: calc(16px + 10 * ((100vw - 1400px) / 1700));line-height: calc(26px + 10 * ((100vw - 1400px) / 1700));}
  .text-lg {font-size: calc(18px + 10 * ((100vw - 1400px) / 1700));line-height: calc(28px + 10 * ((100vw - 1400px) / 1700));}
  .text-xl {font-size: calc(22px + 10 * ((100vw - 1400px) / 1700));line-height: calc(32px + 10 * ((100vw - 1400px) / 1700));}
  .text-xxl {font-size: calc(25px + 10 * ((100vw - 1400px) / 1700));line-height: calc(35px + 10 * ((100vw - 1400px) / 1700));}
  .text-3xl {font-size: calc(30px + 10 * ((100vw - 1400px) / 1700));line-height: calc(40px + 10 * ((100vw - 1400px) / 1700));}
}

@media screen and (min-width: 1980px) {
  .text-sm {font-size: calc(12px + 12 * ((100vw - 1400px) / 1024));line-height: calc(22px + 12 * ((100vw - 1400px) / 1024));}
  .text-md {font-size: calc(14px + 12 * ((100vw - 1400px) / 1024));line-height: calc(24px + 12 * ((100vw - 1400px) / 1024));}
  .text-base {font-size: calc(16px + 12 * ((100vw - 1400px) / 1024));line-height: calc(26px + 12 * ((100vw - 1400px) / 1024));}
  .text-lg {font-size: calc(18px + 12 * ((100vw - 1400px) / 1024));line-height: calc(28px + 12 * ((100vw - 1400px) / 1024));}
  .text-xl {font-size: calc(22px + 12 * ((100vw - 1400px) / 1024));line-height: calc(32px + 12 * ((100vw - 1400px) / 1024));}
  .text-xxl {font-size: calc(25px + 12 * ((100vw - 1400px) / 1024));line-height: calc(35px + 12 * ((100vw - 1400px) / 1024));}
  .text-3xl {font-size: calc(30px + 12 * ((100vw - 1400px) / 1024));line-height: calc(40px + 12 * ((100vw - 1400px) / 1024));}
}